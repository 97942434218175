import React from 'react';
import { Helmet } from 'react-helmet';
import { iwocaBaseUrl } from 'aphrodite-shared/util/helpers';

class RedirectPage extends React.Component {
    render() {
        const { redirectTo } = this.props.pageContext;
        const redirectToIwocaUrl = iwocaBaseUrl(redirectTo);
        return (
            <React.Fragment>
                <Helmet>
                    <meta
                        httpEquiv="refresh"
                        content={`0; url=${redirectToIwocaUrl}`}
                    />
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `window.location.href = ${redirectToIwocaUrl}`
                        }}
                    />
                    <title>Redirecting...</title>
                </Helmet>
                <noscript>
                    <p>
                        If you are not redirected automatically, follow this
                        <a href={redirectToIwocaUrl}>
                            {' '}
                            link to {redirectToIwocaUrl}
                        </a>
                    </p>
                </noscript>
            </React.Fragment>
        );
    }
}

export default RedirectPage;
